import React, { Suspense, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Counter from './counter'
import WOW from "wow.js"
import Loading from './components/Util/Loading'
import MyErrorBoundary from "./components/vendor/ErrorBoundary"

import {
  logo,
  digital_wallet_icon
} from './components/Util/Images'
import 'animate.css'
import './App.css'

const PageNotFound = React.lazy(() => import('./components/Pages/PageNotFound'));
const Header = React.lazy(() => import('./components/Inc/Header.inc'));
const Footer = React.lazy(() => import('./components/Inc/Footer.inc'));
const Breadcrumb = React.lazy(() => import('./components/Util/Breadcrumb'));
const Home = React.lazy(() => import("./components/Pages/Home"));
const About = React.lazy(() => import("./components/Pages/About"));
const Contact = React.lazy(() => import("./components/Pages/Contact"));
const Commission = React.lazy(() => import('./components/Pages/Commission'));
const Whyus = React.lazy(() => import('./components/Pages/Whyus'));
const News = React.lazy(() => import('./components/Pages/News'));
const Turorial = React.lazy(() => import('./components/Pages/Turorial'));
const Faq = React.lazy(() => import('./components/Pages/Faq'));
const Service = React.lazy(() => import('./components/Pages/Service'));
const Cooperation = React.lazy(() => import('./components/Pages/Cooperation'));
const Legal = React.lazy(() => import('./components/Pages/Legal'));
const Privacy = React.lazy(() => import('./components/Pages/Privacy'));
const MoneyLaundering = React.lazy(() => import('./components/Pages/MoneyLaundering'));

const homeBreadcrumb = { text: "صفحه اصلی", key: "Files", url: "./" }
const BreadcrumbItem = {
  about: [
    homeBreadcrumb,
    { text: "درباره ما", key: "Files", url: null },
  ],
  contact: [
    homeBreadcrumb,
    { text: "تماس با ما", key: "Files", url: null },
  ],
  commition: [
    homeBreadcrumb,
    { text: "کارمزد", key: "Files", url: null },
  ],
  news: [
    homeBreadcrumb,
    { text: "اخبار دنیای اقتصاد", key: "Files", url: null },
  ],
  faq: [
    homeBreadcrumb,
    { text: "سوالات متداول", key: "Files", url: null },
  ],
  tutorial: [
    homeBreadcrumb,
    { text: "آموزش", key: "Files", url: null },
  ],
  cooperation: [
    homeBreadcrumb,
    { text: "درخواست همکاری", key: "Files", url: null },
  ],
  machineLaudering: [
    homeBreadcrumb,
    { text: "مجازات پولشویی", key: "Files", url: null },
  ],
  whyus: [
    homeBreadcrumb,
    { text: "چرا صرافی مایا؟", key: "Files", url: null },
  ],
  legal: [
    homeBreadcrumb,
    { text: "شرایط استفاده", key: "Files", url: null },
  ],
  privacy: [
    homeBreadcrumb,
    { text: "حریم خصوصی", key: "Files", url: null },
  ],
}

const app_name = "صرافی مایا";

export default function App() {
  const supportWidget = useRef(null)

  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animate__animated",
      offset: 0,
      mobile: true,
      live: true,
    })
    wow.init()

    let Tawk_API = {}, Tawk_LoadStart = new Date()
    const tawkScript = document.createElement("script")
    tawkScript.async = true
    tawkScript.src = 'https://embed.tawk.to/610191a3649e0a0a5cce6129/1fbn21q6g'
    tawkScript.setAttribute('crossorigin', '*')
    document.head.appendChild(tawkScript)
  }, [])

  return (
    <>
      <Router>
        <MyErrorBoundary>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/about">
                <Header />
                <Breadcrumb items={BreadcrumbItem.about} maxDisplayedItems={3} />
                <About app_name={app_name} logo={logo.default} imgAlternative={app_name} />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route path="/contact">
                <Header />
                <Breadcrumb items={BreadcrumbItem.contact} maxDisplayedItems={3} />
                <Contact />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route path="/commission">
                <Header />
                <Breadcrumb items={BreadcrumbItem.commition} maxDisplayedItems={3} />
                <Commission />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route path="/whyus">
                <Header />
                <Breadcrumb items={BreadcrumbItem.whyus} maxDisplayedItems={3} />
                <Whyus />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/news">
                <Header />
                <Breadcrumb items={BreadcrumbItem.news} maxDisplayedItems={3} />
                <News />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/faq">
                <Header />
                <Breadcrumb items={BreadcrumbItem.faq} maxDisplayedItems={3} />
                <Faq />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route path="/service">
                <Header />
                <Breadcrumb items={BreadcrumbItem.faq} maxDisplayedItems={3} />
                <Service />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route path="/cooperation">
                <Header />
                <Breadcrumb items={BreadcrumbItem.cooperation} maxDisplayedItems={3} />
                <Cooperation />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/tutorial">
                <Header />
                <Breadcrumb items={BreadcrumbItem.tutorial} maxDisplayedItems={3} />
                <Turorial />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/legal/terms">
                <Header />
                <Breadcrumb items={BreadcrumbItem.legal} maxDisplayedItems={3} />
                <Legal />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/money-laundering">
                <Header />
                <Breadcrumb items={BreadcrumbItem.machineLaudering} maxDisplayedItems={3} />
                <MoneyLaundering />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/legal/privacy">
                <Header />
                <Breadcrumb items={BreadcrumbItem.privacy} maxDisplayedItems={3} />
                <Privacy />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route exact path="/">
                <Header />
                <Home
                  logo={logo.default}
                  imgAlternative={app_name}
                  digital_wallet_icon={digital_wallet_icon}
                />
                <Footer logo={logo.default} imgAlternative={app_name} />
              </Route>

              <Route component={PageNotFound} />
            </Switch>
          </Suspense>
        </MyErrorBoundary>
      </Router>
    </>
  )
}
